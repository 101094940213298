header.site-header {
    .header-banner {
        .heading-content {
            text-transform: none !important;

            .heading-title {
                hr.small {
                    margin: 0;
                }
            }

            .buttons-header {
                @media (max-width: 769px) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }
    .arrow-down-section {
        .arrow-down {
            &:after {
                transform: none !important;
            }
        }
    }
}
