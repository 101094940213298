section.features-section {
    @media (max-width: 769px) {
        .section-header {
          padding: 0 30px;
          font-size: 22px;
        }
        .section-content {
          padding: 0 30px;
        }
      }

    .features{
        .features-top{
            @media (max-width: 769px) {
                width: 100%;
                margin-left: 0;
              }
        }
    }
}