section.blog-section {
    @media (max-width: 769px) {
        .section-header {
            padding: 0 30px;
            font-size: 22px;
        }
        .section-content {
            padding: 0 30px;
        }
    }

    .blog-articles-container {
        margin-left: 0 !important;
        margin-right: 0 !important;

        article.blog-article {
            .article-content {
                figure {
                    &.highlight {
                        margin-top: 2em;
                        margin-bottom: 2em;
                    }
                }

                table {
                    display: block;
                    overflow-x: auto;
                    white-space: nowrap;
                    border: none;
                }

                h2,
                h3,
                h4 {
                    margin-top: 1.5em;
                    margin-bottom: 0.5em;
                }
                .article-text {
                    width: auto !important;
                }
            }
        }
    }

    .view-posts {
        @media (max-width: 769px) {
            padding-left: 15px;
        }
        hr.normal {
            margin: 20px 0;
        }
    }
}
