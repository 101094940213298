.rouge-table {
  .code {
    pre {
      margin-left: 25px;
    }
  }
}
.lineno {
  position: relative;
  &:after {
    background: #b4b4b4;
    position: absolute;
    height: 100%;
    content: "";
    right: -7px;
    width: 1px;
    top: 0;
  }
}
.highlight .hll {
  background-color: #ffffcc;
}
.highlight .c {
  color: #008000;
} /* Comment */
.highlight .err {
  border: 1px solid #ff0000;
} /* Error */
.highlight .k {
  color: #0000ff;
} /* Keyword */
.highlight .cm {
  color: #008000;
} /* Comment.Multiline */
.highlight .cp {
  color: #0000ff;
} /* Comment.Preproc */
.highlight .c1 {
  color: #008000;
} /* Comment.Single */
.highlight .cs {
  color: #008000;
} /* Comment.Special */
.highlight .ge {
  font-style: italic;
} /* Generic.Emph */
.highlight .gh {
  font-weight: bold;
} /* Generic.Heading */
.highlight .gp {
  font-weight: bold;
} /* Generic.Prompt */
.highlight .gs {
  font-weight: bold;
} /* Generic.Strong */
.highlight .gu {
  font-weight: bold;
} /* Generic.Subheading */
.highlight .kc {
  color: #0000ff;
} /* Keyword.Constant */
.highlight .kd {
  color: #0000ff;
} /* Keyword.Declaration */
.highlight .kn {
  color: #0000ff;
} /* Keyword.Namespace */
.highlight .kp {
  color: #0000ff;
} /* Keyword.Pseudo */
.highlight .kr {
  color: #0000ff;
} /* Keyword.Reserved */
.highlight .kt {
  color: #2b91af;
} /* Keyword.Type */
.highlight .s {
  color: #a31515;
} /* Literal.String */
.highlight .nc {
  color: #2b91af;
} /* Name.Class */
.highlight .ow {
  color: #0000ff;
} /* Operator.Word */
.highlight .sb {
  color: #a31515;
} /* Literal.String.Backtick */
.highlight .sc {
  color: #a31515;
} /* Literal.String.Char */
.highlight .sd {
  color: #a31515;
} /* Literal.String.Doc */
.highlight .s2 {
  color: #a31515;
} /* Literal.String.Double */
.highlight .se {
  color: #a31515;
} /* Literal.String.Escape */
.highlight .sh {
  color: #a31515;
} /* Literal.String.Heredoc */
.highlight .si {
  color: #a31515;
} /* Literal.String.Interpol */
.highlight .sx {
  color: #a31515;
} /* Literal.String.Other */
.highlight .sr {
  color: #a31515;
} /* Literal.String.Regex */
.highlight .s1 {
  color: #a31515;
} /* Literal.String.Single */
.highlight .ss {
  color: #a31515;
} /* Literal.String.Symbol */

/* Make line numbers unselectable: excludes line numbers from copy-paste user ops */
.highlight .lineno {
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
}
.lineno::-moz-selection {
  background-color: transparent;
} /* Mozilla specific */
.lineno::selection {
  background-color: transparent;
} /* Other major browsers */
