@import "modules/_color-skin", "customizations/customcolors";

.article-content {
  table,
  th,
  td {
    border: 1px solid $simple-gray;
  }

  th,
  td {
    padding: 5px;
  }

  th {
    background-color: $light-blue;
  }
  .rouge-table {
    border: 0;
    width: unset;
    th,
    td {
      border: 0;
    }
  }
}

.training-category {
  margin-top: 80px;
}

ul.page-list {
  list-style: square;
  li {
    margin-left: 20px;
    line-height: 1.5;
  }
  &.job-style {
    li {
      margin-top: 20px;
      list-style-type: square;
    }
  }
}

ul {
  list-style: square;
  margin-bottom: 7px;
  &.menu {
    list-style: none;
  }
  li {
    margin-left: 20px;
    line-height: 1.5;
  }
}

.social-icons {
  ul {
    list-style-type: none !important;
    li {
      line-height: 0 !important;
      margin-left: 0 !important;
    }
  }
}

.site-sidebar {
  .categories-widget {
    .categories-box {
      list-style-type: none !important;
      li {
        line-height: 0 !important;
        margin-left: 0 !important;
      }
    }
  }
}

ol {
  list-style: decimal;
  margin-bottom: 7px;
  li {
    margin-left: 20px;
    line-height: 1.5;
  }
}

.blog-divider {
  margin: 37px 0;
  height: 1.1px;
}

.search {
  position: relative;
  height: 20px;
  width: 20px;
  top: 1px;
}

.nav-menu {
  a {
    svg {
      fill: #fff;
      transition: 0.3s ease;
    }
    &:hover {
      svg {
        fill: #0041c8;
      }
    }
  }
}

.custom-blog-title {
  margin: 0 0 50px !important;
  position: relative;
  top: -7px;
}

.event-part {
  display: inline-block;
  position: relative;
  &:before {
    content: "";
    width: 100%;
    height: 2px;
    bottom: -9px;
    background: #0041c8;
    position: absolute;
  }
}

.event-details {
  ul {
    list-style-type: square;
    margin-left: 18px;
  }
}

.event-small {
  ul {
    list-style-type: square;
    margin-left: 18px;
  }
}

.event-margin {
  margin-bottom: 30px !important;
}

.timeline-date {
  p {
    margin: 0;
  }
}

.job-divider {
  margin-top: 40px;
}

.job-skill {
  display: flex;
  cursor: pointer;
  &:hover {
    i {
      color: #0041c8;
    }
  }
  .job-icon {
    font-size: 25px;
    padding-right: 10px;
  }
  .job-desc {
    margin-bottom: 30px;
    font-size: 22px;
  }
}

.cursor {
  width: 34px;
  height: 34px;
  position: relative;
  .circle {
    box-sizing: border-box;
    background: transparent;
    border: 4px solid #acacac;
    border-radius: 58px;
    height: 100%;
    width: 100%;
  }

  .dot {
    box-sizing: border-box;
    background-color: #acacac;
    left: calc(50% - 7px);
    top: calc(50% - 7px);
    position: absolute;
    border-radius: 50%;
    height: 14px;
    width: 14px;
  }
}

@media (max-width: 769px) {
  .training-category-mobile {
    padding: 0 15px;
  }
}

.job-right-padding {
  padding-right: 40px;
}

.page-layout {
  overflow-x: hidden;
}

.page_simplecontent {
  min-height: 400px;
  padding: 30px 0;
}

@media (max-width: 769px) {
  .main-logo {
    width: calc(100% - 70px) !important;
    margin: 0 auto;
  }
}

.timeline {
  min-height: 200px;
  position: relative;
  margin: 50px 0;
  .divider {
    margin-top: 55px;
    @media (max-width: 767px) {
      margin-top: 0;
    }
  }
  &:before {
    top: 0;
    content: "";
    width: 2px;
    height: 100%;
    display: flex;
    background: #acacac;
    position: absolute;
    left: calc(50% - 1px);
  }

  .time-post {
    &:nth-child(odd) {
      padding-right: 40px;
      .cursor {
        position: absolute;
        margin-top: 12px;
        right: -17px;
        @media (max-width: 767px) {
          & {
            right: 0;
            left: -1px;
          }
        }
      }
    }
    &:nth-child(even) {
      padding-left: 40px;
      .cursor {
        position: absolute;
        margin-top: 12px;
        left: -17px;
        @media (max-width: 767px) {
          & {
            left: -1px !important;
          }
        }
      }
    }
    &.diff {
      margin-top: 80px;
    }
    @media (max-width: 767px) {
      & {
        padding: 0 0 0 45px !important;
        margin-top: 40px;
      }
    }
  }

  @media (max-width: 767px) {
    &:before {
      left: 0;
    }
  }
}

#search-results {
  list-style-type: none !important;
  margin-top: 52px;
  li {
    margin: 0;
  }
}

.find-form {
  display: flex;
  .search-field {
  }
  .submit-field {
    width: 80px;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .event-small {
    margin-top: 30px;
  }
}

.info-bio {
  font-size: 10px;
}

.logo-landing {
  @media (min-width: 767px) {
    & {
      visibility: hidden;
    }
  }
}
