section.portfolio-section {
    @media (max-width: 769px) {

        .section-header {
          padding: 0 30px;
          font-size: 22px;
        }
        .section-content {
          padding: 0 30px;
        }
      }

      .portfolio-grid {
        margin-left: -30px !important;
        margin-right: -30px !important;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 769px) {
            margin-left: -15px !important;
            margin-right: -15px !important;
          }

          .item{
            @media (max-width: 769px) {
                width: 100%;
              }
          }
      }
}

section.portfolio-section{
  .portfolio-grid{
    text-align: center;
  }
}