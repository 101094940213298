section.site-section {
    header.section-header {
        @media (max-width: 769px) {
            h2 {
                word-wrap: break-word;
                font-size: 22px;
            }
        }

        hr.normal {
            margin: 40px 0;
        }
    }
}
