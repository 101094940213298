footer.site-footer {
    .footer-info {
        .footer-info-text {
            @media (max-width: 769px) {
                padding: 35px 15px;
            }
        }
        .social-icons {
            @media (max-width: 769px) {
                padding: 0 15px;
            }
        }
    }
    .footer-copyright {
        @media (max-width: 769px) {
            padding: 30px 15px;
        }
        a {
            color: $global-text;
        }
    }
}
